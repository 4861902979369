import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './Step1.scss';
import {
    Condition, conditions, Municipality, realEstateStoreContext,
    RealEstateType, realEstateTypes
} from '../../../../../../store/components/RealEstateStore';
import Button from '../../../../../../components/ui/Button/Button';
import {clientsStoreContext} from '../../../../../../store/components/ClientStore';
import Select from '../../../../../../components/ui/Select/Select';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {observer} from 'mobx-react';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {clone} from '../../../../../../utils/functions';
import MunicipalitiesCombobox from '../../../../../../components/ui/MunicipalitiesCombobox/MunicipalitiesCombobox';
import {Province, provinceStoreContext} from '../../../../../../store/components/ProvinceStore';

type Step1Props = {
    onNextStep: () => void;
}

const Step1: React.FC<Step1Props> = observer((props) => {
    const {i18n, locale} = useContext(i18nContext);
    const clientStoreCtx = useContext(clientsStoreContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext)
    const realEstates = useMemo(() => clone(realEstateTypes).map((realEstate: RealEstateType) => {
        realEstate.name = i18n.translations[locale].realEstateTypes[realEstate.name];
        return realEstate;
    }), [i18n.translations, locale]);
    const conditionsTranslated = useMemo(() => clone(conditions).map((condition: Condition) => {
        condition.name = i18n.translations[locale].conditions[condition.name];
        return condition;
    }), [i18n.translations, locale]);
    const getRealEstate = useCallback(() => {
        const realEstateType = clientStoreCtx.client.realEstateType;
        const value = realEstateType && realEstates.find((real: RealEstateType) => real.value === realEstateType);
        return value ? value : null;
    }, [clientStoreCtx.client.realEstateType, realEstates]);
    const getCondition = useCallback(() => {
        const condition = clientStoreCtx.client.condition;
        const value = condition && conditionsTranslated.find((con: Condition) => con.value === condition);
        return value ? value : null;
    }, [clientStoreCtx.client.condition, conditionsTranslated]);
    const getLivingArea = useCallback(() => {
        const livingArea = clientStoreCtx.client.livingArea;
        const value = livingArea ? liningAreaOptions.find((liv) => liv.name === livingArea) : null;
        return value ? value : null;
    }, [clientStoreCtx.client.livingArea]);
    const getBedroomCount = useCallback(() => {
        const bedroomCount = clientStoreCtx.client.bedroomCount;
        const value = bedroomCount ? bedroomOptions.find((bed) => bed.name === bedroomCount) : null;
        return value ? value : null;
    }, [clientStoreCtx.client.bedroomCount]);
    const getBathroomCount = useCallback(() => {
        const bathroomCount = clientStoreCtx.client.bathroomCount;
        const value = bathroomCount ? bathroomOptions.find((bath) => bath.name === bathroomCount) : null;
        return value ? value : null;
    }, [clientStoreCtx.client.bathroomCount]);
    const provinceStoreCtx = useContext(provinceStoreContext);
    const [province, setProvince] = useState<Province>(provinceStoreCtx.getProvince(clientStoreCtx.client.province));
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {setValue, handleSubmit} = methods;
    const [realEstateInitBy, setRealEstateInitBy] = useState<RealEstateType | null>(getRealEstate());
    const [condition, setCondition] = useState<Condition | null>(getCondition());
    const [livingArea, setLivingArea] = useState<ClientSelect | null>(getLivingArea());
    const [bathroom, setBathroom] = useState<ClientSelect | null>(getBathroomCount());
    const [bedroom, setBedroom] = useState<ClientSelect | null>(getBedroomCount());

    useEffect(() => {
        const type = getRealEstate();
        setRealEstateInitBy(type ? type : null);
        setValue('realEstateType', type ? type.value : '');
    }, [clientStoreCtx.client.realEstateType, setValue, getRealEstate]);
    useEffect(() => {
        const conditionValue = getCondition();
        setCondition(conditionValue ? conditionValue : null);
        setValue('condition', conditionValue ? conditionValue.value : '');
    }, [clientStoreCtx.client.condition, getCondition, setCondition, setValue]);
    useEffect(() => setLivingArea(getLivingArea()), [clientStoreCtx.client.livingArea, getLivingArea]);
    useEffect(() => setBedroom(getBedroomCount()), [clientStoreCtx.client.bedroomCount, getBedroomCount]);
    useEffect(() => setBathroom(getBathroomCount()), [clientStoreCtx.client.bathroomCount, getBathroomCount]);
    useEffect(() => {
        if (!clientStoreCtx.client.municipality && realEstateStoreCtx.catalogFilter.municipality) {
            clientStoreCtx.mergeClient({municipality: realEstateStoreCtx.catalogFilter.municipality});
        }
    }, [clientStoreCtx, realEstateStoreCtx.catalogFilter.municipality]);
    useEffect(() => {
        setProvince(provinceStoreCtx.getProvince(clientStoreCtx.client.province));
    }, [clientStoreCtx.client.province, provinceStoreCtx]);

    const handleChangeRealEstateType = (realEstate: RealEstateType) => {
        const municipality = clientStoreCtx.client.municipality;
        clientStoreCtx.clearClient();
        clientStoreCtx.mergeClient({realEstateType: realEstate.value, municipality});
    }
    const handleChangeCondition = (condition: Condition) => {
        clientStoreCtx.mergeClient({condition: condition.value});
    }
    const handleChangeProvince = (province: Province) => {
        clientStoreCtx.mergeClient({province: province.name, municipality: ''});
    }
    const handleChangeLivingArea = (livingArea: ClientSelect) => {
        clientStoreCtx.mergeClient({livingArea: livingArea.name});
    }
    const handleChangeBathroom = (bathRoom: ClientSelect) => {
        clientStoreCtx.mergeClient({bathroomCount: bathRoom.name});
    }
    const handleChangeBedRoom = (bedRoom: ClientSelect) => {
        clientStoreCtx.mergeClient({bedroomCount: bedRoom.name});
    }
    const handleSelectMunicipality = (municipality: Municipality | null) => {
        clientStoreCtx.mergeClient({municipality: municipality ? municipality.name : ''});
    }
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        props.onNextStep();
    }
    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content">
            <div className="row center-xs center-sm center-md center-lg center-xl">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Select
                        prefixTemplate={(item: RealEstateType) => <i className={`${item.iconName}`}/>}
                        items={realEstates}
                        initBy={realEstateInitBy}
                        textKey={'name'}
                        registerProps={{name: 'realEstateType', options: {required: true}}}
                        callback={handleChangeRealEstateType}
                        defaultText={i18n.t('clientModal.step1.realEstateTypePlaceholder')}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <Select
                        items={conditionsTranslated}
                        initBy={condition}
                        textKey={'name'}
                        registerProps={{name: 'condition', options: {required: true}}}
                        callback={handleChangeCondition}
                        defaultText={i18n.t('clientModal.step1.conditionPlaceholder')}
                    />
                </div>
            </div>
            <div className="row center-xs center-sm center-md center-lg center-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <Select
                        items={provinces}
                        initBy={province}
                        textKey={'name'}
                        registerProps={{name: 'province', options: {required: true}}}
                        callback={handleChangeProvince}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <MunicipalitiesCombobox
                        province={province}
                        initBy={clientStoreCtx.client.municipality ? clientStoreCtx.client.municipality : null}
                        onSelect={handleSelectMunicipality}
                        icon={'icon-location'}
                    />
                </div>
            </div>
            <div className="row center-xs center-sm center-md center-lg center-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <Select
                        items={liningAreaOptions}
                        initBy={livingArea}
                        textKey={'name'}
                        callback={handleChangeLivingArea}
                        defaultText={i18n.t('clientModal.step1.livingAreaPlaceholder')}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <Select
                        items={bathroomOptions}
                        initBy={bathroom}
                        textKey={'name'}
                        callback={handleChangeBathroom}
                        defaultText={i18n.t('clientModal.step1.bathroomPlaceholder')}
                        className={"select"}
                    />
                </div>
            </div>
            <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <Select
                        items={bedroomOptions}
                        initBy={bedroom}
                        textKey={'name'}
                        callback={handleChangeBedRoom}
                        defaultText={i18n.t('clientModal.step1.bedroomPlaceholder')}
                        className={"select select-top"}
                    />
                </div>
            </div>
        </div>
        <div className="modal-actions">
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 ta-center">
                    <Button className="button" type="submit">{i18n.t('clientModal.step1.button')}</Button>
                </div>
            </div>
        </div>
        </form>
    </FormProvider>)
});
type ClientSelect = {
    id: number,
    name: string;
};

const liningAreaOptions: ClientSelect[] = [
    {
        id: 1,
        name: '0-100',
    },
    {
        id: 2,
        name: '100-150',
    },
    {
        id: 3,
        name: '150-200',
    },
    {
        id: 4,
        name: '200+',
    }
];

const bathroomOptions: ClientSelect[] = [
    {
        id: 1,
        name: '0-1',
    },
    {
        id: 2,
        name: '1-2',
    },
    {
        id: 3,
        name: '2-3',
    },
    {
        id: 4,
        name: '3+',
    }
];

const bedroomOptions: ClientSelect[] = [
    {
        id: 1,
        name: '0-1',
    },
    {
        id: 2,
        name: '1-2',
    },
    {
        id: 3,
        name: '2-3',
    },
    {
        id: 3,
        name: '3-4',
    },
    {
        id: 3,
        name: '4-5',
    },
    {
        id: 4,
        name: '5+',
    }
];

type Inputs = {
    realEstateType: string,
    city: string,
    condition: string;
    livingArea: string;
    bathroomCount: string;
    bedroomCount: string;
};

export default Step1;

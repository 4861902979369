import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import Modal from '../../../../components/ui/Modal/Modal';
import FieldText from '../../../../components/ui/FieldText/FieldText';
// import Textarea from '../../../../components/ui/Textarea/Textarea';
import Button from '../../../../components/ui/Button/Button';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {Feedback, feedbackStoreContext} from '../../../../store/components/FeedbackStore';
import './Banner2Modal.scss';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import FieldPhone from '../../../../components/ui/FieldPhone/FieldPhone';

type FeedbackModalProps = {
    feedbackType: string;
    onOpenPrivacyModal: () => void;
    onClose: (isSuccess: boolean) => void;
}

const Banner2Modal: React.FC<FeedbackModalProps> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const feedbackStoreCtx = useContext(feedbackStoreContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {handleSubmit} = methods;
    const [isRequest, setRequest] = useState(false);
    const [feedbackType, setFeedbackType] = useState(props.feedbackType);
    // const [isChecked, setChecked] = useState(false);
    useEffect(() => {
        setFeedbackType(props.feedbackType);
        const type = ['mail', 'call'].includes(props.feedbackType) ? 'opinion' : props.feedbackType;
        feedbackStoreCtx.merge({type});
    }, [feedbackStoreCtx, props.feedbackType]);

    const handleCloseModal = () => {
        props.onClose(false);
        document.body.classList.remove('ReactModal__Body--open');
    }
    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        feedbackStoreCtx.merge({[fieldName]: event.target.value});
        if (fieldName === 'phone') {
            methods.setValue('phone2', event.target.value);
            methods.trigger('phone2').then(() => {});
        }
    }
    // const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setChecked(event.target.checked);
    //     setValue('privacy', event.target.checked)
    //     trigger('privacy').then(() => {});
    // }
    const onSubmit: SubmitHandler<Inputs> = () => {
        const data: Feedback = JSON.parse(JSON.stringify(feedbackStoreCtx.get()));
        let key: string;
        for (key in data) {
            // @ts-ignore
            if (data[key] === '') {
                // @ts-ignore
                data[key] = null;
            }
            // @ts-ignore
            if (key === 'phone' && data[key] === null) {
                data.phoneCode = null;
            }
        }
        setRequest(true);
        feedbackStoreCtx.create(data)
            .then((result) => {
                if (result) {
                    props.onClose(true);
                }
            }).finally(() => setRequest(false))
    }

    const handleOpenPrivacyModal = () => {
        props.onOpenPrivacyModal && props.onOpenPrivacyModal();
    }

    return (<Modal
        className={'ReactModal__Content-banner2'}
        isOpen={true}
        onRequestClose={handleCloseModal}
    >
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-content">
                    <i className="icon-close" onClick={handleCloseModal}/>
                    <div className="ReactModal__Content-banner2-description">
                        <h2>{i18n.t('banner2Modal.title')}</h2>
                        <p>{i18n.t('banner2Modal.description')}</p>
                    </div>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <FieldText
                                placeholder={i18n.t('feedbackModal.fullNamePlaceholder')}
                                value={feedbackStoreCtx.feedback.fullName ?? ''}
                                registerProps={{name: 'fullName', options: {required: true, onChange: handleChangeFieldText}}}
                            />
                        </div>
                    </div>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        {feedbackType && feedbackType !== 'call' &&
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <FieldText
                                    placeholder={i18n.t('feedbackModal.emailPlaceholder')}
                                    value={feedbackStoreCtx.feedback.email ?? ''}
                                    registerProps={{name: 'email', options: {required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, onChange: handleChangeFieldText}}}
                                />
                            </div>
                        }
                        {feedbackType && feedbackType !== 'mail' &&
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input type="hidden" {...methods.register('phone2', {required: true, onChange: handleChangeFieldText})}/>
                                <FieldPhone
                                    type="tel"
                                    className={`field-phone ${methods && methods.formState.errors && methods.formState.errors.phone2 ? 'field-phone--invalid' : ''}`}
                                    placeholder={'_________'}
                                    value={feedbackStoreCtx.feedback.phone ?? ''}
                                    onChange={handleChangeFieldText}
                                    name={'phone'}
                                    maxLength={9}
                                    phoneCode={feedbackStoreCtx.feedback.phoneCode ?? ''}
                                    onChangePhoneCode={(phoneCode) => feedbackStoreCtx.merge({phoneCode})}
                                />
                            </div>
                        }
                    </div>
                    {/*<div className="row center-xs center-sm center-md center-lg center-xl">*/}
                    {/*    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">*/}
                    {/*        <Textarea*/}
                    {/*            name="description"*/}
                    {/*            value={feedbackStoreCtx.feedback.description ?? ''}*/}
                    {/*            onChange={handleChangeFieldText}*/}
                    {/*            placeholder={i18n.t('feedbackModal.descriptionPlaceholder')}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="modal-actions">
                    <div className="row center-xs">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ta-center">
                            <Button disabled={isRequest} className="button button--light button--circle" onClick={handleOpenPrivacyModal}>
                                {i18n.t('banner2Modal.button1Text')}
                            </Button>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ta-center">
                            <Button disabled={isRequest} className="button button--circle" type="submit">
                                {i18n.t('banner2Modal.button2Text')}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    </Modal>)
});

type Inputs = {
    privacy: boolean;
    fullName: string,
    email: string,
    phone: string,
    phone2: string,
    description: string;
};

export default Banner2Modal;

import React, {useContext} from 'react';
import {i18nContext} from '../../components/i18n/I18nProvider';
import './AboutUs.scss';
import Button from '../../components/ui/Button/Button';
import {useOutletContext} from 'react-router-dom';
import banner from './images/banner-background.webp';
import founder1 from './images/11.webp';
import founder2 from './images/22.webp';
import founder3 from './images/33.webp';
import founder4 from './images/44.webp';
import founder5 from './images/55.webp';
import background1 from './images/1-1.svg';
import background2 from './images/2-2.svg';
import background3 from './images/3-3.svg';
import ContactForm from '../../components/ContactForm/ContactForm';

const AboutUs: React.FC<{}> = () => {
    const {i18n} = useContext(i18nContext);
    const {setFeedbackModalProps, setFeedbackSuccessModalProps} = useOutletContext<any>();
    const handleCloseContactForm = (isSuccess: boolean) => {
        if (isSuccess) {
            setFeedbackSuccessModalProps({isOpen: true});
        }
    }

    return (<div className="about">
        <section className="about__banner">
            <div className="about__banner-scroller"></div>
            <div className="about__banner-image">
                <img src={banner} alt="domus banner"/>
            </div>
            <div className="about__banner-text">
                <h1>
                    {i18n.t('about.bannerH1')}
                </h1>
                <p>
                    {i18n.t('about.bannerDescription')}
                </p>
                <Button type="button" onClick={() => setFeedbackModalProps({isOpen: true, feedbackType: 'buy'})}>
                    {i18n.t('about.bannerAction')}
                </Button>
            </div>
        </section>
        <section className="about__team">
            <h2>
                {i18n.t('about.teamTitle')}
            </h2>
            <div className="polygon">
                <svg viewBox="0 0 360 360" preserveAspectRatio="none" overflow="visible">
                    <polygon points="0,0 0,360 360,360" className="main"></polygon>
                </svg>
            </div>

            <div className="about__team-cards">
                <img className="about__team-background about__team-background-3" src={background3} alt={''}/>
                <div className="about__team-cards-item">
                    <img src={founder1} alt='Kate'/>
                    <h4>{i18n.t('about.team.Kate.position')}</h4>
                    <h5>{i18n.t('about.team.Kate.name')}</h5>
                </div>
                <div className="about__team-cards-item">
                    <img src={founder2} alt='David'/>
                    <h4>{i18n.t('about.team.David.position')}</h4>
                    <h5>{i18n.t('about.team.David.name')}</h5>
                </div>
                <div className="about__team-cards-item">
                    <img src={founder3} alt='Yana'/>
                    <h4>{i18n.t('about.team.Yana.position')}</h4>
                    <h5>{i18n.t('about.team.Yana.name')}</h5>
                </div>
                <div className="about__team-cards-item">
                    <img src={founder4} alt='Dmitry'/>
                    <h4>{i18n.t('about.team.Dmitry.position')}</h4>
                    <h5>{i18n.t('about.team.Dmitry.name')}</h5>
                </div>
                <div className="about__team-cards-item">
                    <img src={founder5} alt='José'/>
                    <h4>{i18n.t('about.team.José.position')}</h4>
                    <h5>{i18n.t('about.team.José.name')}</h5>
                </div>
            </div>

            <div className="about__team-progress">
                <img className="about__team-background about__team-background-2" src={background2} alt={''}/>
                <div className="about__team-progress-item">
                    <div className="about__team-progress-item-icon">
                        <i className="icon-clock"/>
                    </div>
                    <div className="about__team-progress-item-description">
                        <h4>10+</h4>
                        <p>{i18n.t('about.experience.years')}</p>
                    </div>
                </div>
                <div className="about__team-progress-item">
                    <div className="about__team-progress-item-icon">
                        <i className="icon-hands"/>
                    </div>
                    <div className="about__team-progress-item-description">
                        <h4>100+</h4>
                        <p>{i18n.t('about.experience.transactions')}</p>
                    </div>
                </div>
                <div className="about__team-progress-item">
                    <div className="about__team-progress-item-icon">
                        <i className="icon-home-funny"/>
                    </div>
                    <div className="about__team-progress-item-description">
                        <h4>{i18n.t('about.experience.over')} 210</h4>
                        <p>{i18n.t('about.experience.investors')}</p>
                    </div>
                </div>
                <div className="about__team-progress-item">
                    <div className="about__team-progress-item-icon">
                        <i className="icon-stars"/>
                    </div>
                    <div className="about__team-progress-item-description">
                        <h4>10/10</h4>
                        <p>{i18n.t('about.experience.feedback')}</p>
                    </div>
                </div>
            </div>
            <img className="about__team-background about__team-background-1" src={background1} alt={''}/>
        </section>
        <section className="about__team-contact-form">
            <ContactForm feedbackType={'opinion'} onClose={handleCloseContactForm} />
        </section>
    </div>);
}

export default AboutUs;

import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

const feedbackClearState: Feedback = {
    privacy: false,
    type: 'opinion',
    fullName: '',
    phone: '',
    phoneCode: '+34',
    email: '',
    description: '',
    notes: '',
    status: 'new',
}

class Store {
    feedback: Feedback = {...{}, ...feedbackClearState};
    constructor() {
        makeObservable(this, {
            feedback: observable,
            merge: action,
            clear: action,
            get: action,
        });
    }

    get() {
        return this.feedback;
    }

    async create(data: Feedback) {
        try {
            return await api.request('POST', '/feedback', data);
        } catch (err) {}
    }

    merge(data: any) {
        this.feedback = {...this.feedback, ...data};
    }

    clear() {
        this.feedback = {...{}, ...feedbackClearState};
    }
}

export type Feedback = {
    id?: string;
    privacy: boolean;
    type: string;
    fullName: string | null;
    phone: string | null;
    phoneCode: string | null;
    email: string | null;
    description: string | null;
    notes: string | null;
    status: string;
    createdAt?: string;
}

export type FeedbackStore = {
    feedback: Feedback;
    get: () => Feedback;
    create: (data: Feedback) => Promise<Feedback>;
    merge: (data: any) => void;
    clear: () => void;
}

export const feedbackStore = new Store();
export const feedbackStoreContext = createContext(feedbackStore);

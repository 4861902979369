import React, {useContext, useState} from 'react';
import './PrivacyModal.scss';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import {realEstateStoreContext} from '../../../../store/components/RealEstateStore';
import Modal from '../../../../components/ui/Modal/Modal';
import Button from '../../../../components/ui/Button/Button';
import Checkbox from '../../../../components/ui/Checkbox/Checkbox';

type PrivacyModalProps = {
    onClose: (isConfirmed: boolean) => void;
}

const PrivacyModal: React.FC<PrivacyModalProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    const realStateStoreCtx = useContext(realEstateStoreContext);
    const [isChecked, setChecked] = useState(false);

    const handleCloseModal = () => {
        props.onClose(false);
        realStateStoreCtx.clearRealEstate();
        document.body.classList.remove('ReactModal__Body--open');
        const params = new URLSearchParams(window.location.search);
        const isNeedEvaluateModalOpen = params.get('evaluate') === 'true';
        if (isNeedEvaluateModalOpen) {
            window.location.href = '/';
        }
    }

    const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }

    const handleApplyPrivacy = () => {
        props.onClose(true);
    }

    return (<Modal
        className={'ReactModal__Content-privacy'}
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
    >
        <div className="modal-header">
            <div className="row between-xs">
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1">
                </div>
                <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10 ta-center">
                    {i18n.t('privacyModal.header.title')}
                </div>
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right">
                    <i className="icon-close" onClick={handleCloseModal}/>
                </div>
            </div>
        </div>
        <div className="modal-content">
            <div className="row">
                <div className="col description">
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section1.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section1.description1')}
                        </p>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section2.title')}
                        </h3>
                        <h4>
                            {i18n.t('privacyModal.description.section2.list.title')}
                        </h4>
                        <ol>
                            <li>{i18n.t('privacyModal.description.section2.list.items.1')}</li>
                            <li>{i18n.t('privacyModal.description.section2.list.items.2')}</li>
                            <li>{i18n.t('privacyModal.description.section2.list.items.3')}</li>
                            <li>{i18n.t('privacyModal.description.section2.list.items.4')}</li>
                            <li>{i18n.t('privacyModal.description.section2.list.items.5')}</li>
                        </ol>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section3.title')}
                        </h3>
                        <h4>
                            {i18n.t('privacyModal.description.section3.list.title')}
                        </h4>
                        <ol>
                            <li>{i18n.t('privacyModal.description.section3.list.items.1')}</li>
                            <li>{i18n.t('privacyModal.description.section3.list.items.2')}</li>
                            <li>{i18n.t('privacyModal.description.section3.list.items.3')}</li>
                        </ol>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section4.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section4.description1')}
                        </p>
                        <h4>
                            {i18n.t('privacyModal.description.section4.list.title')}
                        </h4>
                        <ol>
                            <li>{i18n.t('privacyModal.description.section4.list.items.1')}</li>
                            <li>{i18n.t('privacyModal.description.section4.list.items.2')}</li>
                            <li>{i18n.t('privacyModal.description.section4.list.items.3')}</li>
                        </ol>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section5.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section5.description1')}
                        </p>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section6.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section6.description1')}
                        </p>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section7.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section7.description1')}
                        </p>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section8.title')}
                        </h3>
                        <h4>
                            {i18n.t('privacyModal.description.section8.list.title')}
                        </h4>
                        <ol>
                            <li>{i18n.t('privacyModal.description.section8.list.items.1')}</li>
                            <li>{i18n.t('privacyModal.description.section8.list.items.2')}</li>
                            <li>{i18n.t('privacyModal.description.section8.list.items.3')}</li>
                            <li>{i18n.t('privacyModal.description.section8.list.items.4')}</li>
                            <li>{i18n.t('privacyModal.description.section8.list.items.5')}</li>
                        </ol>
                        <h4>
                            {i18n.t('privacyModal.description.section8.list2.title')}
                        </h4>
                        <ol>
                            <li>{i18n.t('privacyModal.description.section8.list2.items.1')} info@reselecta.com</li>
                            <li>{i18n.t('privacyModal.description.section8.list2.items.2')}</li>
                            <li>{i18n.t('privacyModal.description.section8.list2.items.3')}</li>
                        </ol>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section9.title')}
                        </h3>
                        <h4>
                            {i18n.t('privacyModal.description.section9.list.title')}
                        </h4>
                        <ol>
                            <li>{i18n.t('privacyModal.description.section9.list.items.1')} info@reselecta.com</li>
                            <li>{i18n.t('privacyModal.description.section9.list.items.2')}</li>
                            <li>{i18n.t('privacyModal.description.section9.list.items.3')}</li>
                        </ol>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section10.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section10.description1')}
                        </p>
                    </section>
                    <section className="precontract-section">
                        <h3>
                            {i18n.t('privacyModal.description.section11.title')}
                        </h3>
                        <p>
                            {i18n.t('privacyModal.description.section11.description1')}
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <div className="modal-actions">
            <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <Button disabled={!isChecked} onClick={handleApplyPrivacy}>
                        {i18n.t('privacyModal.button')}
                    </Button>
                </div>
                <div className="col">
                    <Checkbox onChange={handleChangePrivacy} checked={isChecked}>
                        {i18n.t('privacyModal.checkbox')}
                    </Checkbox>
                </div>
            </div>
        </div>
    </Modal>)
}

export default PrivacyModal;

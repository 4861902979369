import React, {useContext, useRef, useState} from 'react';
import MunicipalitiesCombobox from '../../../../components/ui/MunicipalitiesCombobox/MunicipalitiesCombobox';
import {Municipality} from '../../../../store/components/RealEstateStore';
import './Searcher2.scss';
import Popover from '../../../../components/ui/Popover/Popover';
import {useNavigate} from 'react-router-dom';
import {Province, provinceStoreContext} from '../../../../store/components/ProvinceStore';

type Searcher2Props = {}

const Searcher2: React.FC<Searcher2Props> = (props) => {
    const navigate = useNavigate();
    const provinceStoreCtx = useContext(provinceStoreContext);
    const [provinces] = useState(provinceStoreCtx.getProvinces());
    const [province, setProvince] = useState<Province>(provinceStoreCtx.getProvince('Valencia'));
    const [municipality, setMunicipality] = useState<Municipality | null>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const handleSelectProvince = (province: Province) => {
        setProvince(province);
        // @ts-ignore
        setTimeout(() => popoverRef.current && popoverRef.current.close(), 50);
    }
    const handleSelectMunicipality = (municipality: Municipality | null) => setMunicipality(municipality);
    const handleNavigateToCatalog = () => {
        if (municipality) {
            navigate(`/buy?province=${province.name}&realEstateType=&municipality=${municipality.name}&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        } else {
            navigate(`/buy?province=${province.name}&realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        }
    }

    return (<div className="searcher">
        <div className="searcher__field">
            <Popover
                className="field-city-dropdown-wrapper"
                ref={popoverRef}
                content={<div className="searcher__field-city-dropdown">
                    {provinces.map((p: Province) => {
                        return (<button key={p.name}
                            className={`searcher__field-city-dropdown-item ${province.name === p.name}`}
                            onClick={() => handleSelectProvince(p)}>
                            <div>
                                <i className="icon-building1" />
                                {p.name}
                            </div>
                        </button>)
                    })}
                </div>}>
                <div className="field-city-dropdown__trigger">
                    <div className="searcher__field-city">
                        <i className="icon-building1" />
                        <span>{province.name}</span>
                        <i className="icon-arrow" />
                    </div>
                </div>
            </Popover>
            <div className="searcher__field-input">
                <MunicipalitiesCombobox province={province} onSelect={handleSelectMunicipality} mode={'clear'}/>
                <div className="searcher__field-button" onClick={handleNavigateToCatalog}>
                    <i className="icon-search1"/>
                </div>
            </div>
        </div>
    </div>)
}

export default Searcher2;

import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {realEstateStore, RealEstateStore} from './components/RealEstateStore';
import {ClientStore, clientStore} from './components/ClientStore';
import {feedbackStore, FeedbackStore} from './components/FeedbackStore';
import {provinceStore, ProvinceStore} from './components/ProvinceStore';

class Store {
    realEstateStore: RealEstateStore = realEstateStore;
    clientStore: ClientStore = clientStore;
    feedbackStore: FeedbackStore = feedbackStore;
    provinceStore: ProvinceStore = provinceStore;
    isLoading: boolean = true;
    constructor() {
        makeObservable(this, {
            realEstateStore: observable,
            clientStore: observable,
            feedbackStore: observable,
            provinceStore: observable,
            isLoading: observable,
            setLoading: action,
        });

        this.provinceStore.get().then(() => this.setLoading(false));
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}

export const store = new Store();
export const storeContext = createContext(store);

import React from 'react';
import './RealEstateCardSkileton.scss';

const RealEstateCardSkileton: React.FC<{}> = (props) => {
    return(<div className="real-estate__card real-estate__card--skeleton">
        <div className="real-estate__card-preview">
             <svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.139 155.139">
                 <g>
                     <g>
                         <path d="M125.967,51.533V20.314h-16.862V38.06L77.569,12.814L0,74.869h21.361v67.455h112.416v-67.45h21.361
L125.967,51.533z M125.925,134.472H97.546V82.37H57.58v52.103H29.202V71.146l48.356-38.689l48.355,38.689v63.326H125.925z"/>
                     </g>
                 </g>
             </svg>
        </div>
        <div className="real-estate__card-details">
            <div className="real-estate__card-marketing-address">
                <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
            </div>
            <div className="real-estate__card-presentation-address">
                <i className="icon-local"/>
                <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
            </div>
            <div className="real-estate__card-description">
                <div className="real-estate__card-description-metrics">
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-bed"/> <div className="skeleton__rect skeleton__rect--w-36 skeleton__rect--h-20"/>
                        </div>
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-Bath"/> <div className="skeleton__rect skeleton__rect--w-36 skeleton__rect--h-20"/>
                        </div>
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-sq-all"/> <div className="skeleton__rect skeleton__rect--w-36 skeleton__rect--h-20"/>
                        </div>
                        <div className="real-estate__card-description-metrics-item">
                            <i className="icon-sq-live"/> <div className="skeleton__rect skeleton__rect--w-36 skeleton__rect--h-20"/>
                        </div>
                </div>
                <div className="real-estate__card-description-price">
                    <div className="skeleton__rect skeleton__rect--w-76 skeleton__rect--h-20"/>
                </div>
            </div>
        </div>
    </div>)
}

export default RealEstateCardSkileton;

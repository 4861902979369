import React, {useContext, useEffect, useState} from 'react';
import './Header.scss';
import {Link, useLocation} from 'react-router-dom';
import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';
import {ReactComponent as Menu} from './menu.svg';
import {i18nContext} from '../../../components/i18n/I18nProvider';

type HeaderProps = {
    onOpenEvaluate: () => void;
    onOpenFeedback: (type?: string) => void;
}
const Header: React.FC<HeaderProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    const [isOpen, setOpen] = useState(false);

    const location = useLocation();
    useEffect(() => {
        const header = document.querySelector('header');
        const content = document.querySelector('.content');
        if (window.location.hash !== '#/' && window.location.hash !== '') {
            // @ts-ignore
            header.classList.add('header--scrolled');
            // @ts-ignore
            content.classList.add('content-padded');
        } else {
            const hero = document.querySelector('.home__banner-scroller');
            // @ts-ignore
            content.classList.remove('content-padded');
            const heroOptions = {
                root: null,
                threshold: 0.1,
                rootMargin: "0px"
            };
            const heroObserver = new IntersectionObserver(function(entries, heroObserver) {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) {
                        // @ts-ignore
                        header.classList.add("header--scrolled");
                    } else {
                        // @ts-ignore
                        header.classList.remove("header--scrolled");
                    }
                });
            }, heroOptions);
            // @ts-ignore
            heroObserver.observe(hero);
        }
    }, [location]);

    const setScrolledState = () => {
        if (window.location.pathname === '/') {
            const header = document.querySelector('header');
            if (window.pageYOffset <= 100 && header && header.classList.contains('header--scrolled')) {
                header && header.classList.remove('header--scrolled');
            } else if (window.pageYOffset <= 100 && header && !header.classList.contains('header--scrolled')) {
                header && header.classList.add('header--scrolled');
            }
        }
    }

    const handleCloseNavigation = () => {
        setOpen(false);
        setScrolledState();
    }

    return (<header>
        <div className="row header">
            <div className="col header__logotype">
                <Link to={'/'} onClick={() => {
                    handleCloseNavigation();
                    window.scrollTo(0, 0);
                }}>
                    <div className="logotype" />
                </Link>
            </div>
            <div className="col header__navigation">
                <Link className="header__navigation-item" to={'/buy'} onClick={() => {
                    window.scrollTo(0, 0);
                    handleCloseNavigation();
                }}>{i18n.t('header.links.buy')}</Link>
                <Link className="header__navigation-item" to={'/sell'} onClick={() => {
                    window.scrollTo(0, 0);
                    handleCloseNavigation();
                }}>{i18n.t('header.links.sell')}</Link>
                <div className="header__navigation-item" onClick={props.onOpenEvaluate}>{i18n.t('header.links.evaluate')}</div>
                <Link className="header__navigation-item" to={'/extras'} onClick={() => {
                    handleCloseNavigation();
                    window.scrollTo(0, 0);
                }}>{i18n.t('header.links.extras')}</Link>
                {/*<Link className="header__navigation-item nav-how-it-works" to={'/how-it-works'} onClick={() => {*/}
                {/*    handleCloseNavigation();*/}
                {/*    window.scrollTo(0, 0);*/}
                {/*}}>{i18n.t('header.links.howItWorks')}</Link>*/}
                <Link className="header__navigation-item nav-how-it-works" to={'/about'} onClick={() => {
                    handleCloseNavigation();
                    window.scrollTo(0, 0);
                }}>{i18n.t('header.links.about')}</Link>
                <div className="header__language-switcher">
                    <LanguageSwitcher />
                </div>
            </div>
            <div className="header__navigation-trigger" onClick={() => {
                setOpen(!isOpen)
                setScrolledState();
            }}>
                <Menu />
            </div>
        </div>
        {isOpen && <div className="header__navigation-small">
            <Link className="header__navigation-item" to={'/buy'} onClick={() => {
                window.scrollTo(0, 0);
                handleCloseNavigation();
            }}>{i18n.t('header.links.buy')}</Link>
            <Link className="header__navigation-item" to={'/sell'} onClick={() => {
                window.scrollTo(0, 0);
                handleCloseNavigation();
            }}>{i18n.t('header.links.sell')}</Link>
            <div className="header__navigation-item" onClick={() => {
                props.onOpenEvaluate();
                handleCloseNavigation();
            }}>{i18n.t('header.links.evaluate')}</div>
            <Link className="header__navigation-item" to={'/extras'} onClick={() => {
                handleCloseNavigation();
                window.scrollTo(0, 0);
            }}>{i18n.t('header.links.extras')}</Link>
            {/*<Link className="header__navigation-item" to={'/how-it-works'} onClick={() => {*/}
            {/*    handleCloseNavigation();*/}
            {/*    window.scrollTo(0, 0);*/}
            {/*}}>{i18n.t('header.links.howItWorks')}</Link>*/}
            <Link className="header__navigation-item nav-how-it-works" to={'/about'} onClick={() => {
                handleCloseNavigation();
                window.scrollTo(0, 0);
            }}>{i18n.t('header.links.about')}</Link>
            <LanguageSwitcher onLocaleSwitch={handleCloseNavigation}/>
        </div>}
    </header>)
}

export default Header;

import React, {useContext} from 'react';
import './Sell.scss';
import Button from '../../components/ui/Button/Button';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useOutletContext} from 'react-router-dom';
import Headline from '../../components/ui/Headline/Headline';
import Calculator from '../../components/Calculator/Calculator';

const Sell: React.FC<any> = (props) => {
    const {i18n} = useContext(i18nContext);
    const {setRealEstateModalProps} = useOutletContext<any>();
    const handleOpenSellModal = () => {
        setRealEstateModalProps({isOpen: true});
    }

    return (<>
        <div className="sell">
            <Headline>Sell</Headline>
            <div className="sell-miniature sell-miniature-top"></div>
            <div className="sell-miniature sell-miniature-middle"></div>
            <div className="sell-miniature sell-miniature-triangle"></div>
            <section className="sell-section sell-section-arrow-right">
                <div className="sell-section__counter">
                    01
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.1.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.1.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle sell-section-arrow-right">
                <div className="sell-section__counter">
                    02
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.2.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.2.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-right sell-section-arrow-left">
                <div className="sell-section__counter">
                    03
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.3.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.3.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle sell-section-arrow-left">
                <div className="sell-section__counter">
                    04
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.4.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.4.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-arrow-right">
                <div className="sell-section__counter">
                    05
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.5.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.5.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-middle sell-section-arrow-right">
                <div className="sell-section__counter">
                    06
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.6.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.6.description')}
                    </div>
                </div>
            </section>

            <section className="sell-section sell-section-right sell-section-arrow-left">
                <div className="sell-section__counter">
                    07
                </div>
                <div className="sell-section__content-container">
                    <h3 className="sell-section__title">
                        {i18n.t('sell.7.title')}
                    </h3>
                    <div className="sell-section__description">
                        {i18n.t('sell.7.description')}
                    </div>
                </div>
            </section>

            <div className="sell__action">
                <div className="sell-miniature sell-miniature-action"></div>
                <Button onClick={handleOpenSellModal} className="button">{i18n.t('sell.action')}</Button>
            </div>
        </div>

        <Calculator />
    </>);
};

export default Sell;

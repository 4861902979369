import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './Step1.scss';
import {observer} from 'mobx-react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {
    Condition, conditions,
    realEstateStoreContext,
    RealEstateType,
    realEstateTypes
} from '../../../../../../store/components/RealEstateStore';
import Select from '../../../../../../components/ui/Select/Select';
import Button from '../../../../../../components/ui/Button/Button';
import {Province, provinceStoreContext} from '../../../../../../store/components/ProvinceStore';
import FieldRegexp from '../../../../../../components/ui/FieldRegexp/FieldRegexp';

type Step1Props = {
    onNextStep: () => void;
}

const Step1: React.FC<Step1Props> = observer((props) => {
    const {i18n, locale} = useContext(i18nContext);
    const provinceStoreCtx = useContext(provinceStoreContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {setValue, handleSubmit} = methods;

    const realEstates = useMemo(() => JSON.parse(JSON.stringify(realEstateTypes)).map((realEstate: RealEstateType) => {
        realEstate.name = i18n.translations[locale].realEstateTypes[realEstate.name];
        return realEstate;
    }), [locale, i18n.translations]);
    const conditionsTranslated = useMemo(() => JSON.parse(JSON.stringify(conditions)).map((condition: Condition) => {
        condition.name = i18n.translations[locale].conditions[condition.name];
        return condition;
    }), [locale, i18n.translations]);
    const getRealEstate = useCallback(() => {
        const realEstateType = realEstateStoreCtx.realEstate.realEstateType;
        return realEstateType && realEstates.find((real: RealEstateType) => real.value === realEstateType);
    }, [realEstateStoreCtx.realEstate.realEstateType, realEstates]);
    const getCondition = useCallback(() => {
        const condition = realEstateStoreCtx.realEstate.condition;
        const value = condition && conditionsTranslated.find((con: Condition) => con.value === condition);
        return value ? value : null;
    }, [realEstateStoreCtx.realEstate.condition, conditionsTranslated]);
    const getBedroomCount = useCallback(() => {
        const bedroomCount = realEstateStoreCtx.realEstate.bedroomCount;
        const value = bedroomCount ? bedroomOptions.find((bed) => bed.name === bedroomCount) : null;
        return value ? value : null;
    }, [realEstateStoreCtx.realEstate.bedroomCount]);
    const getBathroomCount = useCallback(() => {
        const bathroomCount = realEstateStoreCtx.realEstate.bathroomCount;
        const value = bathroomCount ? bathroomOptions.find((bath) => bath.name === bathroomCount) : null;
        return value ? value : null;
    }, [realEstateStoreCtx.realEstate.bathroomCount]);

    const [realEstateInitBy, setRealEstateInitBy] = useState<RealEstateType | null>(getRealEstate());
    const [provinces] = useState<Province[]>(provinceStoreCtx.getProvinces());
    const [province, setProvince] = useState(provinceStoreCtx.getProvince(realEstateStoreCtx.realEstate.province ?? 'Valencia'));
    const [condition, setCondition] = useState<Condition | null>(null);
    const [bathroom, setBathroom] = useState<ClientSelect | null>(getBathroomCount());
    const [bedroom, setBedroom] = useState<ClientSelect | null>(getBedroomCount());

    useEffect(() => {
        const type = getRealEstate();
        setRealEstateInitBy(type ? type : null);
        setValue('realEstateType', type ? type.value : '');
    }, [realEstateStoreCtx.realEstate.realEstateType, setValue, realEstates, setRealEstateInitBy, getRealEstate]);
    useEffect(() => {
        const conditionValue = getCondition();
        setCondition(conditionValue ? conditionValue : null);
        setValue('condition', conditionValue ? conditionValue.value : '');
    }, [realEstateStoreCtx.realEstate, getCondition, setCondition, setValue]);

    useEffect(() => {
        const province = provinceStoreCtx.getProvince(realEstateStoreCtx.realEstate.province);
        setProvince(province);
        setValue('province', province.name);
    }, [realEstateStoreCtx.realEstate.province, provinceStoreCtx, setValue]);
    useEffect(() => setBedroom(getBedroomCount()), [realEstateStoreCtx.realEstate.bedroomCount, getBedroomCount]);
    useEffect(() => setBathroom(getBathroomCount()), [realEstateStoreCtx.realEstate.bathroomCount, getBathroomCount]);

    const handleChangeRealEstateType = (realEstate: RealEstateType) => {
        realEstateStoreCtx.mergeRealState({realEstateType: realEstate.value});
    }
    const handleChangeProvince = (province: Province) => {
        realEstateStoreCtx.mergeRealState({province: province.name, presentationAddress: '', longitude: '', latitude: ''});
    }

    const handleChangeFieldNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        realEstateStoreCtx.mergeRealState({[event.target.name]: event.target.value});
    }
    const handleChangeCondition = (condition: Condition) => {
        realEstateStoreCtx.mergeRealState({condition: condition.value});
    }
    const handleChangeBathroom = (bathRoom: ClientSelect) => {
        realEstateStoreCtx.mergeRealState({bathroomCount: bathRoom.name});
    }
    const handleChangeBedRoom = (bedRoom: ClientSelect) => {
        realEstateStoreCtx.mergeRealState({bedroomCount: bedRoom.name});
    }
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        props.onNextStep();
    }

    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content modal-content-step1">
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12">
                        <Select
                            prefixTemplate={() => <i className="icon-building1"/>}
                            items={provinces}
                            initBy={province}
                            textKey={'name'}
                            registerProps={{name: 'province', options: {required: true}}}
                            callback={handleChangeProvince}
                            defaultText={i18n.t('sellModal.step1.provincePlaceholder')}
                        />
                    </div>
                    <div className="col-xs-12">
                        <Select
                            prefixTemplate={(item: RealEstateType) => <i className={`${item.iconName}`}/>}
                            items={realEstates}
                            initBy={realEstateInitBy}
                            textKey={'name'}
                            registerProps={{name: 'realEstateType', options: {required: true}}}
                            callback={handleChangeRealEstateType}
                            defaultText={i18n.t('sellModal.step1.realEstateTypePlaceholder')}
                        />
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12">
                        <Select
                            items={conditionsTranslated}
                            initBy={condition}
                            textKey={'name'}
                            callback={handleChangeCondition}
                            defaultText={i18n.t('sellModal.step2.conditionPlaceholder')}
                            registerProps={{name: 'condition', options: {required: true}}}
                        />
                    </div>
                    <div className="col-xs-12 field-with-postfix">
                        <FieldRegexp
                            registerProps={{name: 'livingArea', options: {required: true}}}
                            placeholder={i18n.t('sellModal.step2.livingAreaPlaceholder')}
                            regexp={'[^0-9.]'}
                            type="tel"
                            value={realEstateStoreCtx.realEstate.livingArea ? realEstateStoreCtx.realEstate.livingArea : ''}
                            onChange={handleChangeFieldNumber}
                        />
                        <span className="field-postfix">m2</span>
                    </div>
                </div>
                {
                    realEstateStoreCtx.realEstate.realEstateType !== 'land'
                    && <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12">
                            <Select
                                items={bathroomOptions}
                                initBy={bathroom}
                                textKey={'name'}
                                callback={handleChangeBathroom}
                                defaultText={i18n.t('clientModal.step1.bathroomPlaceholder')}
                                className={"select select-top"}
                            />
                        </div>
                        <div className="col-xs-12">
                            <Select
                                items={bedroomOptions}
                                initBy={bedroom}
                                textKey={'name'}
                                callback={handleChangeBedRoom}
                                defaultText={i18n.t('clientModal.step1.bedroomPlaceholder')}
                                className={"select select-top"}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className="modal-actions">
                <div className="row center-xs">
                    <div className="col-xs-12 ta-center">
                        <Button className="button" type="submit">{i18n.t('sellModal.step1.button')}</Button>
                    </div>
                </div>
            </div>
        </form>
    </FormProvider>)
});

type ClientSelect = {
    id: number,
    name: string;
};

const bathroomOptions: ClientSelect[] = [
    {
        id: 1,
        name: '1',
    },
    {
        id: 2,
        name: '2',
    },
    {
        id: 3,
        name: '3',
    },
    {
        id: 4,
        name: '4+',
    }
];

const bedroomOptions: ClientSelect[] = [
    {
        id: 1,
        name: '1',
    },
    {
        id: 2,
        name: '2',
    },
    {
        id: 3,
        name: '3',
    },
    {
        id: 4,
        name: '4+',
    }
];

type Inputs = {
    realEstateType: string,
    province: string,
    condition: string;
};

export default Step1;

import React, {forwardRef, useContext, useEffect, useState} from 'react';
import './Step2.scss';
import {observer} from 'mobx-react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import moment from 'moment';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {RealEstate, realEstateStoreContext} from '../../../../../../store/components/RealEstateStore';
import Button from '../../../../../../components/ui/Button/Button';
import FieldText from '../../../../../../components/ui/FieldText/FieldText';
import Datepicker from '../../../../../../components/ui/Datepicker/Datepicker';
import Textarea from '../../../../../../components/ui/Textarea/Textarea';
import FieldPhone from '../../../../../../components/ui/FieldPhone/FieldPhone';
import Checkbox from '../../../../../../components/ui/Checkbox/Checkbox';
import {Link} from 'react-router-dom';

type Step4Props = {
    onNextStep: () => void;
    onSubmitSuccess: (isSuccess: boolean) => void;
}

const Step2: React.FC<Step4Props> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {setValue, trigger, handleSubmit} = methods;
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const [isChecked, setChecked] = useState(false);
    const [requestDate, setRequestDate] = useState<Date>(realEstateStoreCtx.realEstate.requestDate ? new Date(realEstateStoreCtx.realEstate.requestDate) : new Date());
    const [requestTimeStart, setRequestTimeStart] = useState<Date | null>(realEstateStoreCtx.realEstate.requestTimeStart ? new Date(realEstateStoreCtx.realEstate.requestTimeStart) : null);
    const [requestTimeEnd, setRequestTimeEnd] = useState<Date | null>(realEstateStoreCtx.realEstate.requestTimeEnd ? new Date(realEstateStoreCtx.realEstate.requestTimeEnd) : null);
    const [isRequest, setRequest] = useState(false);
    useEffect(() => {
        if (!realEstateStoreCtx.realEstate.requestDate) {
            handleChangeRequestDate(new Date());
        }
    }, [realEstateStoreCtx.realEstate.requestDate]);
    useEffect(() => {
        realEstateStoreCtx.mergeRealState({requestDate: moment(requestDate ?? new Date()).format()});
    }, [requestDate, realEstateStoreCtx]);
    useEffect(() => {
        if (requestTimeStart) {
            realEstateStoreCtx.mergeRealState({requestTimeStart: moment(requestTimeStart).format()});
        }
    }, [requestTimeStart, realEstateStoreCtx]);
    useEffect(() => {
        if (requestTimeEnd) {
            realEstateStoreCtx.mergeRealState({requestTimeEnd: moment(requestTimeEnd).format()});
        }
    }, [requestTimeEnd, realEstateStoreCtx]);
    const handleSelectRequestType = (type: string) => {
        realEstateStoreCtx.mergeRealState({requestType: type, email: '', phone: '', requestDate: '', requestTimeStart: '', requestTimeEnd: ''});
        if (type === 'call') {
            handleChangeRequestDate(new Date());
        }
    }

    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        realEstateStoreCtx.mergeRealState({[fieldName]: event.target.value});
        if (fieldName === 'phone') {
            methods.setValue('phone2', event.target.value);
            methods.trigger('phone2').then(() => {});
        }
    }

    const handleChangeRequestDate = (date: Date) => {
        setRequestDate(date);
    }

    const handleChangeRequestTimeStart = (time1: Date) => {
        setRequestTimeStart(time1);
    }

    const handleChangeRequestTimeEnd = (time2: Date) => {
        setRequestTimeEnd(time2);
    }

    const handleChangePrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        setValue('privacy', event.target.checked)
        trigger('privacy').then(() => {});
    }

    const onSubmit: SubmitHandler<Inputs> = () => {
        const data: RealEstate = JSON.parse(JSON.stringify(realEstateStoreCtx.getRealEstate()));
        let key: string;
        for (key in data) {
            // @ts-ignore
            if (key === 'street' && data[key] === '') {
                data[key] = data.presentationAddress;
            }
            // @ts-ignore
            if (data[key] === '') {
                // @ts-ignore
                data[key] = null;
            }
            // @ts-ignore
            if (key === 'phone' && data[key] === null) {
                // @ts-ignore
                data.phoneCode = null;
            }
        }
        setRequest(true);
        realEstateStoreCtx.createRealEstate(data)
            .then((result) => {
                if (result) {
                    props.onSubmitSuccess(true);
                }
            }).finally(() => setRequest(false))
    }

    // @ts-ignore
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button type="button" className="field-text" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const requestType = realEstateStoreCtx.realEstate.requestType;
    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content modal-content-step2">
                <div>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 step-description">
                            <h3>{i18n.t('sellModal.step4.title')}</h3>
                        </div>
                    </div>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-6">
                            <Button
                                className={`button button--small button--circle ${requestType !== 'mail' ? 'button--light' : ''}`}
                                onClick={() => handleSelectRequestType('mail')}>
                                <i className="icon-main"/>
                                {i18n.t('sellModal.step4.buttonMail')}
                            </Button>
                        </div>
                        <div className="col-xs-6">
                            <Button
                                className={`button button--small button--circle ${requestType !== 'call' ? 'button--light' : ''}`}
                                onClick={() => handleSelectRequestType('call')}>
                                <i className="icon-phone"/>
                                {i18n.t('sellModal.step4.buttonPhone')}
                            </Button>
                        </div>
                    </div>
                    {requestType && <>
                        <div className="row center-xs center-sm center-md center-lg center-xl">
                            <div className="col-xs-12">
                                <FieldText
                                    placeholder={i18n.t('sellModal.step4.fullNamePlaceholder')}
                                    value={realEstateStoreCtx.realEstate.fullName ?? ''}
                                    registerProps={{name: 'fullName', options: {required: true, onChange: handleChangeFieldText}}}
                                />
                            </div>
                            {requestType === 'mail'
                                ?
                                <div className="col-xs-12">
                                    <FieldText
                                        placeholder={i18n.t('sellModal.step4.emailPlaceholder')}
                                        value={realEstateStoreCtx.realEstate.email ?? ''}
                                        registerProps={{
                                            name: 'email',
                                            options: {
                                                required: true,
                                                //eslint-disable-next-line
                                                pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                                                onChange:
                                                handleChangeFieldText
                                            }
                                        }}
                                    />
                                </div>
                                :
                                <div className="col-xs-12">
                                    <Datepicker
                                        className={`field-text ${methods && methods.formState && methods.formState.errors && methods.formState.errors.requestDate ? 'field-text-invalid' : ''}`}
                                        placeholderText="01/06/2024"
                                        dateFormat={'dd/MM/YYYY'}
                                        selected={requestDate}
                                        onChange={handleChangeRequestDate}
                                        // @ts-ignore
                                        minDate={moment().subtract(1, 'day')._d}
                                        name={'requestDate'}
                                        customInput={<ExampleCustomInput />}
                                    />
                                </div>
                            }
                        </div>
                        {requestType === 'call' && <>
                            <div className="row center-xs center-sm center-md center-lg center-xl">
                                <div className="col-xs-12">
                                    <input type="hidden" {...methods.register('phone2', {required: realEstateStoreCtx.realEstate.requestType === 'call', onChange: handleChangeFieldText})}/>
                                    <FieldPhone
                                        type="tel"
                                        placeholder={'_________'}
                                        className={`field-phone ${methods && methods.formState.errors && methods.formState.errors.phone2 ? 'field-phone--invalid' : ''}`}
                                        value={realEstateStoreCtx.realEstate.phone ?? ''}
                                        onChange={handleChangeFieldText}
                                        name={'phone'}
                                        maxLength={9}
                                        phoneCode={realEstateStoreCtx.realEstate.phoneCode ?? ''}
                                        onChangePhoneCode={(phoneCode) => realEstateStoreCtx.mergeRealState({phoneCode})}
                                    />
                                </div>
                                <div className="col-xs-6">
                                    <Datepicker
                                        placeholderText="12:00"
                                        selected={requestTimeStart}
                                        onChange={handleChangeRequestTimeStart}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeLable={i18n.t('sellModal.step4.fromPlaceholder')}
                                        dateFormat="HH:mm"
                                        timeCaption={i18n.t('sellModal.step4.fromPlaceholder')}
                                        timeFormat="HH:mm"/>
                                </div>
                                <div className="col-xs-6">
                                    <Datepicker
                                        placeholderText="14:00"
                                        selected={requestTimeEnd}
                                        filterTime={(time: any) => {
                                            if (!requestTimeStart) {
                                                return true;
                                            }
                                            return moment(time).format('HH:mm') > moment(requestTimeStart).format('HH:mm');
                                        }}
                                        onChange={handleChangeRequestTimeEnd}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeLable={i18n.t('sellModal.step4.toPlaceholder')}
                                        dateFormat="HH:mm"
                                        timeCaption={i18n.t('sellModal.step4.toPlaceholder')}
                                        timeFormat="HH:mm"/>
                                </div>
                            </div>
                        </>}
                        <div className="row center-xs center-sm center-md center-lg center-xl">
                            <div className="col-xs-12">
                                <Textarea
                                    name="description"
                                    value={realEstateStoreCtx.realEstate.description}
                                    onChange={handleChangeFieldText}
                                    placeholder={i18n.t('sellModal.step4.descriptionPlaceholder')}
                                />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            {requestType && <div className="modal-actions">
                <div className="row center-xs">
                    <div className="col-xs-12 privacy">
                        <Checkbox
                            onChange={handleChangePrivacy}
                            registerProps={{name: 'privacy', options: {required: true, onChange: handleChangePrivacy}}}
                            checked={isChecked}>
                            {i18n.t('sellModal.termOfUseCheckbox.text1')}
                        </Checkbox>
                        <Link target="_blank" to={'/terms-of-use'} >{i18n.t('sellModal.termOfUseCheckbox.text2')}</Link>
                    </div>
                    <div className="col-xs-12 ta-center">
                        <Button disabled={isRequest} className="button" type="submit">{i18n.t('sellModal.step4.button')}</Button>
                    </div>
                </div>
            </div>}
        </form>
    </FormProvider>)
});

type Inputs = {
    requestDate: string;
    fullName: string;
    email: string;
    phone: string;
    phone2: string;
    privacy: boolean;
};

export default Step2;

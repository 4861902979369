import React, {useContext} from 'react';
import './TermsOfUse.scss';
import {i18nContext} from '../../components/i18n/I18nProvider';
import Headline from '../../components/ui/Headline/Headline';

const TermsOfUse: React.FC<{}> = (props) => {
    const {i18n} = useContext(i18nContext);

    return (<div className="terms-of-use">
        <Headline>{i18n.t('termsOfUse.header.title')}</Headline>
        <div className="row">
            <div className="col description">
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section1.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section1.description1')}
                    </p>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section2.title')}
                    </h3>
                    <h4>
                        {i18n.t('termsOfUse.description.section2.list.title')}
                    </h4>
                    <ol>
                        <li>{i18n.t('termsOfUse.description.section2.list.items.1')}</li>
                        <li>{i18n.t('termsOfUse.description.section2.list.items.2')}</li>
                        <li>{i18n.t('termsOfUse.description.section2.list.items.3')}</li>
                        <li>{i18n.t('termsOfUse.description.section2.list.items.4')}</li>
                        <li>{i18n.t('termsOfUse.description.section2.list.items.5')}</li>
                    </ol>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section3.title')}
                    </h3>
                    <h4>
                        {i18n.t('termsOfUse.description.section3.list.title')}
                    </h4>
                    <ol>
                        <li>{i18n.t('termsOfUse.description.section3.list.items.1')}</li>
                        <li>{i18n.t('termsOfUse.description.section3.list.items.2')}</li>
                        <li>{i18n.t('termsOfUse.description.section3.list.items.3')}</li>
                    </ol>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section4.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section4.description1')}
                    </p>
                    <h4>
                        {i18n.t('termsOfUse.description.section4.list.title')}
                    </h4>
                    <ol>
                        <li>{i18n.t('termsOfUse.description.section4.list.items.1')}</li>
                        <li>{i18n.t('termsOfUse.description.section4.list.items.2')}</li>
                        <li>{i18n.t('termsOfUse.description.section4.list.items.3')}</li>
                    </ol>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section5.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section5.description1')}
                    </p>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section6.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section6.description1')}
                    </p>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section7.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section7.description1')}
                    </p>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section8.title')}
                    </h3>
                    <h4>
                        {i18n.t('termsOfUse.description.section8.list.title')}
                    </h4>
                    <ol>
                        <li>{i18n.t('termsOfUse.description.section8.list.items.1')}</li>
                        <li>{i18n.t('termsOfUse.description.section8.list.items.2')}</li>
                        <li>{i18n.t('termsOfUse.description.section8.list.items.3')}</li>
                        <li>{i18n.t('termsOfUse.description.section8.list.items.4')}</li>
                        <li>{i18n.t('termsOfUse.description.section8.list.items.5')}</li>
                    </ol>
                    <h4>
                        {i18n.t('termsOfUse.description.section8.list2.title')}
                    </h4>
                    <ol>
                        <li>{i18n.t('termsOfUse.description.section8.list2.items.1')} info@reselecta.com</li>
                        <li>{i18n.t('termsOfUse.description.section8.list2.items.2')}</li>
                        <li>{i18n.t('termsOfUse.description.section8.list2.items.3')}</li>
                    </ol>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section9.title')}
                    </h3>
                    <h4>
                        {i18n.t('termsOfUse.description.section9.list.title')}
                    </h4>
                    <ol>
                        <li>{i18n.t('termsOfUse.description.section9.list.items.1')} info@reselecta.com</li>
                        <li>{i18n.t('termsOfUse.description.section9.list.items.2')}</li>
                        <li>{i18n.t('termsOfUse.description.section9.list.items.3')}</li>
                    </ol>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section10.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section10.description1')}
                    </p>
                </section>
                <section className="precontract-section">
                    <h3>
                        {i18n.t('termsOfUse.description.section11.title')}
                    </h3>
                    <p>
                        {i18n.t('termsOfUse.description.section11.description1')}
                    </p>
                </section>
            </div>
        </div>
    </div>)
}

export default TermsOfUse;

import React, {useContext, useEffect, useState} from 'react';
import './Root.scss';
import {observer} from 'mobx-react';
import {Outlet, useLocation} from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import RealEstateSuccessModal from './components/RealEstateSuccessModal/RealEstateSuccessModal';
import PrivacyModal from './components/PrivacyModal/PrivacyModal';
import {realEstateStoreContext} from '../../store/components/RealEstateStore';
import FeedbackSuccessModal from './components/FeedbackSuccessModal/FeedbackSuccessModal';
import FeedbackModal from './components/FeedbackModal/FeedbackModal';
import {feedbackStoreContext} from '../../store/components/FeedbackStore';
import ClientModal from './components/ClientModal/ClientModal';
import ClientSuccessModal from './components/ClientSuccessModal/ClientSuccessModal';
import {clientsStoreContext} from '../../store/components/ClientStore';
import BannerModal from './components/BannerModal/BannerModal';
import Banner2Modal from './components/Banner2Modal/Banner2Modal';
import {storeContext} from '../../store/store';
import RealEstateModal2 from './components/RealEstateModal2/RealEstateModal2';

const Root = observer(() => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const storeCtx = useContext(storeContext);
    const feedbackStoreCtx = useContext(feedbackStoreContext);
    const realStateStoreCtx = useContext(realEstateStoreContext);
    const clientStoreCtx = useContext(clientsStoreContext);
    const [privacyModalProps, setPrivacyModalProps] = useState({isOpen: false});
    const [realEstateModalProps, setRealEstateModalProps] = useState({isOpen: false});
    const [realEstateSuccessModalProps, setRealEstateSuccessModalProps] = useState({isOpen: false});
    const [feedbackModalProps, setFeedbackModalProps] = useState({isOpen: false, feedbackType: ''});
    const [feedbackSuccessModalProps, setFeedbackSuccessModalProps] = useState({isOpen: false});
    const [clientModalProps, setClientModalProps] = useState({isOpen: false});
    const [clientSuccessModalProps, setClientSuccessModalProps] = useState({isOpen: false});
    const [bannerModalProps, setBannerModalProps] = useState({isOpen: false});
    const [banner2ModalProps, setBanner2ModalProps] = useState({isOpen: false, feedbackType: 'opinion'});

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const isNeedEvaluateModalOpen = params.get('evaluate') === 'true';
        if (window.location.href.includes('/buy')) {
            // setTimeout(() => setBannerModalProps({isOpen: true}), 3000);
        }
        if (window.location.pathname === '/' && isNeedEvaluateModalOpen) {
            setTimeout(() => setRealEstateModalProps({isOpen: true}), 250);
        }
    }, []);
    const handleOpenEvaluate = () => {
        setRealEstateModalProps({isOpen: true});
    }
    const handleOpenFeedback = (type?: string) => {
        setFeedbackModalProps({isOpen: true, feedbackType: type ?? 'opinion'})
    }
    const handleCloseRealEstateModal = (isSuccess: boolean) => {
        realStateStoreCtx.clearRealEstate();
        setRealEstateModalProps({isOpen: false});
        if (isSuccess) {
            setRealEstateSuccessModalProps({isOpen: true});
        }
    }
    const handleClosePrivacyModal = (isConfirmed: boolean) => {
        setPrivacyModalProps({isOpen: false});
        if (isConfirmed) {
            realStateStoreCtx.mergeRealState({privacy: true});
            setRealEstateModalProps({isOpen: true});
        } else {
            realStateStoreCtx.clearRealEstate();
        }
    }
    const handleCloseRealEstateSuccessModal = () => {
        setRealEstateSuccessModalProps({isOpen: false});
    }
    const handleCloseFeedbackModal = (isSuccess: boolean) => {
        feedbackStoreCtx.clear();
        setFeedbackModalProps({isOpen: false, feedbackType: ''});
        if (isSuccess) {
            setFeedbackSuccessModalProps({isOpen: true});
        }
    }

    const handleCloseClientModal = (isSuccess: boolean) => {
        clientStoreCtx.clearClient();
        setClientModalProps({isOpen: false});
        if (isSuccess) {
            setClientSuccessModalProps({isOpen: true});
        }
    }

    if (storeCtx.isLoading) {
        return null;
    }

    return (
        <>
            {privacyModalProps.isOpen && <PrivacyModal onClose={handleClosePrivacyModal}/>}
            {realEstateModalProps.isOpen && <RealEstateModal2 onClose={handleCloseRealEstateModal}/>}
            {realEstateSuccessModalProps.isOpen && <RealEstateSuccessModal onClose={handleCloseRealEstateSuccessModal}/>}
            {feedbackModalProps.isOpen && <FeedbackModal onClose={handleCloseFeedbackModal} feedbackType={feedbackModalProps.feedbackType}/>}
            {feedbackSuccessModalProps.isOpen && <FeedbackSuccessModal onClose={() => setFeedbackSuccessModalProps({isOpen: false})}/>}
            {clientModalProps.isOpen && <ClientModal onClose={handleCloseClientModal}/>}
            {clientSuccessModalProps.isOpen && <ClientSuccessModal onClose={() => setClientSuccessModalProps({isOpen: false})}/>}
            {bannerModalProps.isOpen && <BannerModal
                onClose={() => setBannerModalProps({isOpen: false})}
                onSuccess={() => {
                    setBannerModalProps({isOpen: false});
                    setBanner2ModalProps({isOpen: true, feedbackType: 'opinion'});
                }}
            />}
            {banner2ModalProps.isOpen && <Banner2Modal
                feedbackType={banner2ModalProps.feedbackType}
                onClose={() => setBanner2ModalProps({isOpen: false, feedbackType: 'opinion'})}
                onOpenPrivacyModal={() => {
                    setBanner2ModalProps({isOpen: false, feedbackType: 'opinion'});
                    handleOpenEvaluate();
                }}
            />}
            <Header onOpenEvaluate={handleOpenEvaluate} onOpenFeedback={handleOpenFeedback}/>
            <div className="content">
                <Outlet context={{setPrivacyModalProps, setRealEstateModalProps, setFeedbackModalProps, setFeedbackSuccessModalProps, setClientModalProps}}/>
            </div>
            <Footer onOpenEvaluate={handleOpenEvaluate} onOpenFeedback={handleOpenFeedback}/>
        </>
    );
});

export default Root;

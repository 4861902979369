import {action, makeObservable, observable} from 'mobx';
import {createContext} from 'react';
import {api} from '../../services/Api';

const clientClearState: Client = {
    realEstateType: '',
    condition: '',
    province: 'Valencia',
    municipality: '',
    livingArea: '',
    bathroomCount: '',
    bedroomCount: '',
    extras: [],
    price: '',
    buyDeadline: '',
    fullName: '',
    phone: '',
    phoneCode: '+34',
    email: '',
    description: '',
    notes: '',
    status: 'new',
}

class Store {
    client: Client = {...{}, ...clientClearState};
    constructor() {
        makeObservable(this, {
            client: observable,
            mergeClient: action,
            clearClient: action,
            getClient: action,
        });
    }

    getClient() {
        return this.client;
    }

    async createClient(data: Client) {
        try {
            return await api.request('POST', '/client', data);
        } catch (err) {}
    }

    mergeClient(data: any) {
        this.client = {...this.client, ...data};
    }

    clearClient() {
        this.client = {...{}, ...clientClearState};
    }
}

export type Client = {
    id?: string;
    realEstateType: string;
    condition: string;
    province: string;
    municipality: string;
    livingArea: string;
    bathroomCount: string;
    bedroomCount: string;
    extras: number[];
    price: string;
    buyDeadline: string;
    fullName: string;
    phone: string;
    phoneCode: string;
    email: string;
    description: string;
    notes: string;
    status: string;
    createdAt?: string;
}

export type ClientStore = {
    client: Client;
    getClient: () => Client;
    createClient: (data: Client) => Promise<Client>;
    mergeClient: (data: any) => void;
    clearClient: () => void;
}

export const clientStore = new Store();
export const clientsStoreContext = createContext(clientStore);

import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import {useLocation, useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {
    RealEstate, RealEstateExtra,
    RealEstatePreview,
    realEstateStoreContext,
    RealEstateType, realEstateTypes
} from '../../store/components/RealEstateStore';
import {currencyFormatter, extraIconsMap} from '../../utils/functions';
import {i18nContext} from '../../components/i18n/I18nProvider';
import './CatalogCardDetails.scss';
import Button from '../../components/ui/Button/Button';
import EmptyPlaceholder from '../Catalog/components/EmptyPlaceholder/EmptyPlaceholder';
import {observer} from 'mobx-react';
import {feedbackStoreContext} from '../../store/components/FeedbackStore';
import {Map, Marker} from '@vis.gl/react-google-maps';
import Calculator from '../../components/Calculator/Calculator';

type ImageGalleryType = {
    original: string;
    thumbnail: string
};

const CatalogCardDetails: React.FC = observer(() => {
    const imageGalleryRef = useRef<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {setFeedbackModalProps} = useOutletContext<any>();
    const {i18n, locale} = useContext(i18nContext);
    const feedbackStoreCtx = useContext(feedbackStoreContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const realEstates = useMemo(() => JSON.parse(JSON.stringify(realEstateTypes)).map((realEstate: RealEstateType) => {
        realEstate.name = i18n.translations[locale].realEstateTypes[realEstate.name];
        return realEstate;
    }), [locale, i18n.translations]);
    const getRealEstate = useCallback((realEstate: string) => {
        const realEstateType = realEstate;
        return realEstateType && realEstates.find((real: RealEstateType) => real.value === realEstateType);
    }, [realEstates]);
    const [isRealEstateNotExist, setRealEstateNotExist] = useState(false);
    const [realEstate, setRealEstate] = useState<RealEstate>({} as RealEstate);
    const [images, setImages] = useState<{original: string; thumbnail: string}[]>([]);
    const [extrasActive, setExtrasActive] = useState<RealEstateExtra[]>([] as RealEstateExtra[]);
    const [isNavigateFromCatalog] = useState(location.key !== 'default');
    const [isRealEstateLoading, setRealEstateLoading] = useState(true);
    const [scale, setScale] = useState(7);

    useEffect(() => {
        realEstateStoreCtx.loadRealEstateExtras().then((extraList) => {
            realEstateStoreCtx.loadRealEstate(params.id as string)
                .then((realEstate: RealEstate) => {
                    if (!realEstate) {
                        setRealEstateLoading(false);
                        return setRealEstate({} as RealEstate);
                    }
                    setImages((realEstate.previews || []).reduce((acc, image: RealEstatePreview) => {
                        acc.push({original: image.imageUrl, thumbnail: image.imageUrl});
                        return acc;
                    }, [] as ImageGalleryType[]));
                    setRealEstate(realEstate);
                    setExtrasActive((realEstate.extras || []).reduce((acc, id) => {
                        acc.push((extraList || []).find((ex: RealEstateExtra) => Number(ex.id) === Number(id)));
                        return acc;
                    }, [] as RealEstateExtra[]));
                    setRealEstateLoading(false);
                }).catch(() => {
                    // @ts-ignore
                    setRealEstate({});
                    setRealEstateNotExist(true);
                    setRealEstateLoading(false);
                });
        });
    }, [params.id, realEstateStoreCtx, getRealEstate]);

    const handleGoBack = () => {
        if (isNavigateFromCatalog) {
            navigate(-1);
        } else {
            navigate(`/buy?realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        }
    }

    const handlePrint = (event: React.UIEvent<HTMLDivElement>) => {
        event.preventDefault();
        window.print();
    }

    const handleBuyRealEstate = () => {
        setFeedbackModalProps({isOpen: true, feedbackType: 'buy'});
        setTimeout(() => feedbackStoreCtx.merge({description: 'I want to buy ' + window.location.href}), 250);
    }

    if (isRealEstateLoading) {
        return (<div className="catalog-card-details">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    Loading...
                </div>
            </div>
        </div>);
    }

    if (!isRealEstateLoading && isRealEstateNotExist) {
        return (<div className="catalog-card-details">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <EmptyPlaceholder />
                </div>
            </div>
        </div>);
    }

    return (<>
        <div className="real-estate">
            <div className="real-estate__header">
                <h1>
                    { locale === 'en' ? realEstate.realEstateMarketingName : realEstate.realEstateMarketingNameES }
                </h1>
                <div className="real-estate__header-actions">
                    <div className="header-actions__link" onClick={handleGoBack}>
                        <i className="icon-arrow-left" />
                        {i18n.t(isNavigateFromCatalog ? 'catalogDetailsCard.backText' : 'catalogDetailsCard.goText')}
                    </div>
                    <div className="header-actions__controls">
                        <div className="header-actions__controls-item" onClick={handlePrint}>
                            <i className="icon-printer1" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="real-estate__buy">
                <div className="real-estate__buy-price">
                    {currencyFormatter(realEstate.catalogPrice || 0, true)}
                </div>
                <div className="real-estate__buy-action">
                    <Button
                        className={`button`}
                        onClick={handleBuyRealEstate}
                    >
                        BUY
                    </Button>
                </div>
            </div>
            <div className="real-estate__preview">
                <div className="real-estate__preview-gallery">
                    <ImageGallery
                        ref={imageGalleryRef}
                        items={images}
                        showNav={true}
                        showPlayButton={false}
                        showFullscreenButton={true}
                        showBullets={false}
                        renderCustomControls={() => {
                            return <div className="image-gallery-overlay" onClick={() => imageGalleryRef.current.toggleFullScreen()}/>
                        }}
                    />
                    <div className="real-estate__marketing-description">
                        {
                            locale === 'en' ?
                                // @ts-ignore
                                <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescription}} />
                                // @ts-ignore
                                : <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescriptionES}} />
                        }
                    </div>
                </div>
                <div className="real-estate__preview-map">
                    <div className="real-estate__buy">
                        <div className="real-estate__buy-price">
                            {currencyFormatter(realEstate.catalogPrice || 0, true)}
                        </div>
                        <div className="real-estate__buy-action">
                            <Button
                                className={`button`}
                                onClick={handleBuyRealEstate}
                            >
                                BUY
                            </Button>
                        </div>
                    </div>
                    {
                        realEstate.latitude && realEstate.longitude && <div className="map-wrapper">
                            <Map
                                style={{width: '100%', height: '256px'}}
                                defaultCenter={{lat: realEstate.latitude, lng: realEstate.longitude}}
                                defaultZoom={15}
                                gestureHandling={'greedy'}
                                disableDefaultUI={true}
                                zoomControl={true}
                                onZoomChanged={(event) => {
                                    setScale(event.detail.zoom >= 4 ? event.detail.zoom / 2 : 0);
                                }}
                            >
                                <Marker
                                    position={{lat: realEstate.latitude, lng: realEstate.longitude}}
                                    icon={{
                                        path: google.maps.SymbolPath.CIRCLE,
                                        scale: Math.pow(2, scale ?? 0) /  (10 / scale),
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: "#FF0000",
                                        fillOpacity: 0.35,
                                    }}
                                />
                            </Map>
                        </div>
                    }
                    <div className="real-estate__address-location">
                        <span><i className="icon-local"/>{realEstate.presentationManualAddress}</span>
                    </div>
                    <div className="real-estate__characteristics">
                        <div className="real-estate__characteristics-title">
                            {realEstate.realEstateType} characteristics
                        </div>
                        <div className="real-estate__characteristics-row">
                            <div className="real-estate__characteristics-col">
                                {
                                    realEstate.plotArea && <div className="real-estate__characteristics-col-item">
                                        <div className="real-estate__characteristics-col-item-icon">
                                            <i className="icon-sq-all"/>
                                        </div>
                                        <div className="real-estate__characteristics-col-item-name">
                                            Plot area:
                                        </div>
                                        <div className="real-estate__characteristics-col-item-value">
                                            {realEstate.plotArea} m2
                                        </div>
                                    </div>
                                }
                                {
                                    realEstate.livingArea && <div className="real-estate__characteristics-col-item">
                                        <div className="real-estate__characteristics-col-item-icon">
                                            <i className="icon-sq-live"/>
                                        </div>
                                        <div className="real-estate__characteristics-col-item-name">
                                            Living area:
                                        </div>
                                        <div className="real-estate__characteristics-col-item-value">
                                            {realEstate.livingArea} m2
                                        </div>
                                    </div>
                                }
                                {
                                    realEstate.bedroomCount && <div className="real-estate__characteristics-col-item">
                                        <div className="real-estate__characteristics-col-item-icon">
                                            <i className="icon-bed"/>
                                        </div>
                                        <div className="real-estate__characteristics-col-item-name">
                                            Plot area:
                                        </div>
                                        <div className="real-estate__characteristics-col-item-value">
                                            {realEstate.bedroomCount}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="real-estate__characteristics-col">
                                {
                                    realEstate.bathroomCount && <div className="real-estate__characteristics-col-item">
                                        <div className="real-estate__characteristics-col-item-icon">
                                            <i className="icon-Bath"/>
                                        </div>
                                        <div className="real-estate__characteristics-col-item-name">
                                            Bathrooms:
                                        </div>
                                        <div className="real-estate__characteristics-col-item-value">
                                            {realEstate.bathroomCount}
                                        </div>
                                    </div>
                                }
                                {
                                    realEstate.energyClass && realEstate.energyCertificate && <div className="real-estate__characteristics-col-item">
                                        <div className="real-estate__characteristics-col-item-icon">
                                            <i className="icon-green"/>
                                        </div>
                                        <div className="real-estate__characteristics-col-item-name">
                                            Energy certificate:
                                        </div>
                                        <div className="real-estate__characteristics-col-item-value">
                                            {realEstate.energyClass} class
                                        </div>
                                    </div>
                                }
                                {
                                    realEstate.buildingYear && <div className="real-estate__characteristics-col-item">
                                        <div className="real-estate__characteristics-col-item-icon">
                                            <i className="icon-date"/>
                                        </div>
                                        <div className="real-estate__characteristics-col-item-name">
                                            Building year:
                                        </div>
                                        <div className="real-estate__characteristics-col-item-value">
                                            {realEstate.buildingYear}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="real-estate__characteristics-row-separator"></div>
                        <div className="real-estate__characteristics-row real-estate__characteristics-row--extras">
                            {extrasActive.map((extra) => {
                                return (<div className="real-estate__characteristics-col" key={extra.id}>
                                    {
                                        realEstate.plotArea && <div className="real-estate__characteristics-col-item">
                                            <div className="real-estate__characteristics-col-item-icon">
                                                {/*@ts-ignore*/}
                                                <i className={`${extraIconsMap[extra.id]}`}/>
                                            </div>
                                            <div className="real-estate__characteristics-col-item-name">
                                                {i18n.t(`extras.${extra.name}`)}:
                                            </div>
                                            <div className="real-estate__characteristics-col-item-value">
                                                Yes
                                            </div>
                                        </div>
                                    }
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="real-estate__marketing-description">
                {
                    locale === 'en' ?
                        // @ts-ignore
                        <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescription}} />
                        // @ts-ignore
                        : <div className="catalog-card-marketing-description" dangerouslySetInnerHTML={{__html: realEstate.realEstateMarketingDescriptionES}} />
                }
            </div>
        </div>
    <Calculator />
</>)
});

export default CatalogCardDetails;

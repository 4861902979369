import React, {useContext, useEffect, useState} from 'react';
import './Step2.scss';
import {
    RealEstateExtra,
    realEstateStoreContext,
} from '../../../../../../store/components/RealEstateStore';
import Button from '../../../../../../components/ui/Button/Button';
import Select from '../../../../../../components/ui/Select/Select';
import {clientsStoreContext} from '../../../../../../store/components/ClientStore';
import {FormProvider, useForm} from 'react-hook-form';
import {observer} from 'mobx-react';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {extraIconsMap} from '../../../../../../utils/functions';

type Step3Props = {
    onNextStep: () => void;
}

type extraActive = {
    [key: string]: boolean;
}

const Step2: React.FC<Step3Props> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {handleSubmit} = methods;
    const clientStoreCtx = useContext(clientsStoreContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const extras = realEstateStoreCtx.getRealEstateExtras();

    const [extraActive, setExtraActive] = useState<extraActive>(extras.reduce((acc, extra) => {
        acc[extra.id] = !!(clientStoreCtx.client.extras || []).find((id) => id === extra.id);
        return acc;
    }, {} as extraActive));
    const [price, setPrice] = useState<SelectPrice | null>(null);

    useEffect(() => {
        clientStoreCtx.mergeClient({extras: Object.keys(extraActive).reduce((acc, id) => {
                if (extraActive[id]) {
                    acc.push(+id);
                }
                return acc;
            }, [] as number[])});
    }, [extraActive, clientStoreCtx]);

    useEffect(() => {
        const price = clientStoreCtx.client.price;
        const priceValue = price && priceOptions.find((p) => p.value === price);
        setPrice(priceValue ? priceValue : null);
    }, [clientStoreCtx.client.price]);

    const handleToggleExtra = (extra: RealEstateExtra) => {
        const value = !extraActive[extra.id]
        setExtraActive({...extraActive, ...{[extra.id]: value}});
    }
    const handleChangePrice = (price: SelectPrice) => {
        clientStoreCtx.mergeClient({price: price.value});
    }
    const onSubmit = () => {
        props.onNextStep();
    }
    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content">
            <div className="row center-xs center-sm center-md center-lg center-xl">
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <Select
                        items={priceOptions}
                        initBy={price}
                        textKey={'value'}
                        callback={handleChangePrice}
                        defaultText={i18n.t('clientModal.step2.pricePlaceholder')}
                    />
                </div>
            </div>
            <div className="extras-block">
                {extras.map((extra: RealEstateExtra) => {
                    return (<div className="extra" key={extra.id}>
                        <Button
                            className={`button button--circle button--small button--ha-start ${extraActive[extra.id] ? '' : 'button--light'}`}
                            onClick={() => handleToggleExtra(extra)}>
                            {/*@ts-ignore*/}
                            <i className={`${extraIconsMap[extra.id]}`}/>
                            {i18n.t(`extras.${extra.name}`)}
                        </Button>
                    </div>)
                })}
            </div>
        </div>
        <div className="modal-actions">
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 ta-center">
                    <Button className="button" type="submit">{i18n.t('clientModal.step2.button')}</Button>
                </div>
            </div>
        </div>
        </form>
    </FormProvider>)
});
type SelectPrice = {
    id: number;
    value: string;
};
const priceOptions: SelectPrice[] = [
    {
        id: 1,
        value: '0 - 100.000 €'
    },
    {
        id: 2,
        value: '100.000 - 200.000 €'
    },
    {
        id: 3,
        value: '200.000 - 300.000 €'
    },
    {
        id: 4,
        value: '300.000 - 500.000 €'
    },
    {
        id: 5,
        value: '500.000 €+'
    }
];

type Inputs = {
    price: string,
    extras: string[],
};

export default Step2;
